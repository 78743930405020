import { useState } from "react";
import {
  ContentWrapper,
  MenuButton,
  MenuWrapper,
  MobileWrapper,
} from "./styled";
import Timeline from "../../timeline";
import Appearance from "../../appearance";

interface MobileViewProps {
  currentTime: number;
  videoRef: any;
  clickDelete: any;
  handleTextChange: any;
  captions?: any[];
  formatTime: any;
  setValue: any;
  appearance: any;
  fonts: any;
  register: any;
  updateGoogleFontsLink: any;
  scrollingContainerRef: any;
  activeCaptionRef: any;
  isEditing: boolean;
  setIsEditing: any;
  playerHeight?: number;
}

const MobileView = ({
  currentTime,
  videoRef,
  clickDelete,
  handleTextChange,
  captions,
  formatTime,
  setValue,
  appearance,
  fonts,
  register,
  updateGoogleFontsLink,
  playerHeight,
  scrollingContainerRef,
  activeCaptionRef,
  isEditing,
  setIsEditing,
}: MobileViewProps) => {
  const [selection, setSelection] = useState<"appearance" | "captions">(
    "captions"
  );

  const handleSelection = (selection: "appearance" | "captions") => {
    setSelection(selection);
  };

  return (
    <MobileWrapper playerHeight={playerHeight ?? 0}>
      <MenuWrapper playerHeight={playerHeight ?? 0}>
        <MenuButton
          className={selection === "captions" ? "active" : ""}
          onClick={() => handleSelection("captions")}
        >
          Undertekster
        </MenuButton>
        <MenuButton
          className={selection === "appearance" ? "active" : ""}
          onClick={() => handleSelection("appearance")}
        >
          Endre utseende
        </MenuButton>
      </MenuWrapper>
      <ContentWrapper
        className={isEditing ? "is-editing" : ""}
        ref={scrollingContainerRef}
        playerHeight={playerHeight ?? 0}
      >
        {selection === "captions" ? (
          <Timeline
            currentTime={currentTime}
            videoRef={videoRef}
            onDelete={clickDelete}
            onTextChange={handleTextChange}
            captions={captions}
            formatTime={formatTime}
            setValue={setValue}
            activeCaptionRef={activeCaptionRef}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
          />
        ) : (
          <Appearance
            appearance={appearance}
            fonts={fonts}
            register={register}
            setValue={setValue}
            updateGoogleFontsLink={updateGoogleFontsLink}
          />
        )}
      </ContentWrapper>
    </MobileWrapper>
  );
};

export default MobileView;

export const popular_fonts = [
  {
    value: "Poppins",
    title: "Poppins",
    url: "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
    variants: [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic",
    ],
  },
  {
    value: "Lora",
    title: "Lora",
    url: "https://fonts.gstatic.com/s/lora/v35/0QI6MX1D_JOuGQbT0gvTJPa787weuyJGmKxemMeZ.ttf",
    variants: [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic",
    ],
  },
  {
    value: "Raleway",
    title: "Raleway",
    url: "https://fonts.gstatic.com/s/raleway/v34/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCPNLA3JC9c.ttf",
    variants: [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic",
    ],
  },
  {
    value: "ADLaM Display",
    title: "ADLaM Display",
    url: "https://fonts.gstatic.com/s/adlamdisplay/v1/KFOhCnGXkPOLlhx6jD8_b1ZECsHYkYBPY3o.ttf",
    variants: ["regular"],
  },
  {
    value: "Abril Fatface",
    title: "Abril Fatface",
    url: "https://fonts.gstatic.com/s/abrilfatface/v23/zOL64pLDlL1D99S8g8PtiKchm-BsjOLhZBY.ttf",
    variants: ["regular"],
  },
  {
    value: "Courier Prime",
    title: "Courier Prime",
    url: "https://fonts.gstatic.com/s/courierprime/v9/u-450q2lgwslOqpF_6gQ8kELWwZjW-_-tvg.ttf",
    variants: ["regular", "italic", "700", "700italic"],
  },
  {
    value: "Bayon",
    title: "Bayon",
    url: "https://fonts.gstatic.com/s/bayon/v34/9XUrlJNmn0LPFl-pOhYEd2NJ.ttf",
    variants: ["regular"],
  },
  {
    value: "Bebas Neue",
    title: "Bebas Neue",
    url: "https://fonts.gstatic.com/s/bebasneue/v14/JTUSjIg69CK48gW7PXooxW5rygbi49c.ttf",
    variants: ["regular"],
  },
  {
    value: "Lobster",
    title: "Lobster",
    url: "https://fonts.gstatic.com/s/lobster/v30/neILzCirqoswsqX9_oWsMqEzSJQ.ttf",
    variants: ["regular"],
  },
  {
    value: "Oswald",
    title: "Oswald",
    url: "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf",
    variants: ["200", "300", "regular", "500", "600", "700"],
  },
  {
    value: "Bangers",
    title: "Bangers",
    url: "https://fonts.gstatic.com/s/bangers/v24/FeVQS0BTqb0h60ACL5la2bxii28.ttf",
    variants: ["regular"],
  },
  {
    value: "Luckiest Guy",
    title: "Luckiest Guy",
    url: "https://fonts.gstatic.com/s/luckiestguy/v22/_gP_1RrxsjcxVyin9l9n_j2RStR3qDpraA.ttf",
    variants: ["regular"],
  },
];

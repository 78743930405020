import { useMediaQuery } from "react-responsive";
import DesktopView from "./desktop";
import MobileView from "./mobile";
import { MOBILE_VIEW } from "../../../../../variables";
import { useState } from "react";

interface SelectionsProps {
  currentTime: number;
  videoRef: any;
  clickDelete: any;
  handleTextChange: any;
  captions?: any[];
  formatTime: any;
  setValue: any;
  appearance: any;
  fonts: any;
  register: any;
  updateGoogleFontsLink: any;
  playerHeight?: number;
  scrollingContainerRef: any;
  activeCaptionRef: any;
}

const Selections = ({
  currentTime,
  videoRef,
  clickDelete,
  handleTextChange,
  captions,
  formatTime,
  setValue,
  appearance,
  fonts,
  register,
  updateGoogleFontsLink,
  playerHeight = 0,
  scrollingContainerRef,
  activeCaptionRef,
}: SelectionsProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  return isMobile ? (
    <MobileView
      currentTime={currentTime}
      videoRef={videoRef}
      clickDelete={clickDelete}
      handleTextChange={handleTextChange}
      captions={captions}
      formatTime={formatTime}
      setValue={setValue}
      appearance={appearance}
      fonts={fonts}
      register={register}
      updateGoogleFontsLink={updateGoogleFontsLink}
      playerHeight={playerHeight}
      scrollingContainerRef={scrollingContainerRef}
      activeCaptionRef={activeCaptionRef}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
    />
  ) : (
    <DesktopView
      currentTime={currentTime}
      videoRef={videoRef}
      clickDelete={clickDelete}
      handleTextChange={handleTextChange}
      captions={captions}
      formatTime={formatTime}
      setValue={setValue}
      appearance={appearance}
      fonts={fonts}
      register={register}
      updateGoogleFontsLink={updateGoogleFontsLink}
      scrollingContainerRef={scrollingContainerRef}
      activeCaptionRef={activeCaptionRef}
    />
  );
};

export default Selections;

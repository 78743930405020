import { ColorResult } from "react-color";
import ColorSelect from "../../../../../../library/color-select";
import { Label } from "../../../../../../library/label";
import {
  AccordionTitle,
  Field,
  InnerAccordionHeader,
  InputCol,
  InputRow,
} from "../styled";
import { AccordionBody, AccordionHeader, AccordionItem } from "../styled";
import { UseFormSetValue } from "react-hook-form";
import { VideoAppearanceSchema } from "../../schema";

interface IBackgroundSettingsProps {
  isMobile: boolean;
  appearance: any;
  setValue: UseFormSetValue<VideoAppearanceSchema>;
  keyRef: string;
}

const BackgroundSettings: React.FC<IBackgroundSettingsProps> = ({
  isMobile,
  appearance,
  setValue,
  keyRef,
}) => {
  const handleBackgroundColorChange = (color: ColorResult) => {
    const { r, g, b, a } = color.rgb;
    const rgbaString = `rgba(${r}, ${g}, ${b}, ${a})`;
    setValue("appearance.backgroundColor", rgbaString);
  };

  const onResetFontBackground = () => {
    setValue("appearance.backgroundColor", null);
  };

  return (
    <AccordionItem key={keyRef} eventKey={keyRef}>
      <AccordionHeader>
        <InnerAccordionHeader>
          <AccordionTitle>Bakgrunn</AccordionTitle>
        </InnerAccordionHeader>
      </AccordionHeader>
      <AccordionBody>
        <Field isMobile={isMobile}>
          <InputRow>
            <InputCol md={6}>
              <Label>Farge</Label>
              <ColorSelect
                color={appearance?.backgroundColor}
                onChange={handleBackgroundColorChange}
                onReset={onResetFontBackground}
              />
            </InputCol>
          </InputRow>
        </Field>
      </AccordionBody>
    </AccordionItem>
  );
};

export default BackgroundSettings;

import { Dispatch, SetStateAction } from "react";
import Button from "../../../../../../library/button";
import Selections from "../../../../../../library/selections";
import { ButtonsWrapper, SelectionsWrapper, SelectionWrapper } from "./styled";
import { OPTIONS } from "./options";
import { useTranslation } from "react-i18next";

interface SelectTypeViewProps {
  setStep: Dispatch<SetStateAction<2 | 1 | 3 | 4>>;
  setSelectionType: (type: "file" | "recording") => void;
  selectionType: "file" | "recording" | null;
  closeModal: () => void;
}

const SelectTypeView: React.FC<SelectTypeViewProps> = ({
  setStep,
  setSelectionType,
  selectionType,
  closeModal,
}) => {
  const { t } = useTranslation();

  const handleSelection = (type: any) => {
    setSelectionType(type?.value);
  };

  const clickBack = () => {
    closeModal();
  };

  const clickNext = () => {
    setStep(2);
  };

  return (
    <SelectionWrapper>
      <SelectionsWrapper>
        <Selections
          defaultSelected={selectionType}
          options={OPTIONS}
          onSelect={handleSelection}
          type="default"
        />
      </SelectionsWrapper>

      <ButtonsWrapper>
        <Button
          onClick={clickBack}
          variant="regular"
          color="grey"
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.selection-step.buttons.back.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.transcripts.add-modal.content.selection-step.buttons.back.title"
          )}
        </Button>
        <Button
          type="submit"
          variant="regular"
          color="blue"
          onClick={clickNext}
          ariaLabel={
            t(
              "dashboard.pages.transcripts.add-modal.content.selection-step.buttons.next.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.transcripts.add-modal.content.selection-step.buttons.next.title"
          )}
        </Button>
      </ButtonsWrapper>
    </SelectionWrapper>
  );
};

export default SelectTypeView;

import { useEffect, useState } from "react";
import VideoPlayerDesktop from "./desktop";
import VideoPlayerMobile from "./mobile";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";

type VideoPlayerProps = {
  videoUrl: string;
  captions: any;
  configuration: any;
  currentTime: any;
  onTimeUpdate: any;
  videoRef: any;
  playerWidth: number;
};

const VideoPlayer = ({
  videoUrl,
  captions,
  configuration,
  onTimeUpdate,
  videoRef,
  currentTime,
  playerWidth,
}: VideoPlayerProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [mute, setMute] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current?.paused || videoRef.current?.ended) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current?.pause();
      setIsPlaying(false);
    }
  };

  const handleMute = () => {
    if (!videoRef?.current) return;
    videoRef.current.muted = !mute;
    setMute(!mute);
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!videoRef?.current) return;
    const value = e.target.value;
    const volume = parseFloat(value);
    videoRef.current.volume = volume;
    document.documentElement.style.setProperty(
      "--player-volume",
      `${volume * 100}%`
    );
    setVolume(volume);
    setMute(volume === 0);
  };

  const activeSubtitle = () => {
    if (!captions) return "";
    const currentCaption = captions.find(
      (caption: any) =>
        currentTime >= caption.start && currentTime <= caption.end
    );
    return currentCaption ? currentCaption?.text : "";
  };

  const clickFullscreen = () => {
    if (!videoRef.current) return;

    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
  };

  const clickGoBackward = () => {
    if (!videoRef.current) return;
    const skipTime = currentTime - 5;
    videoRef.current.currentTime = skipTime;
  };

  const clickGoForward = () => {
    if (!videoRef.current) return;
    const skipTime = currentTime + 5;
    videoRef.current.currentTime = skipTime;
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.code === "Space" &&
        event.target instanceof HTMLElement &&
        !["INPUT", "TEXTAREA", "DIV"].includes(event.target.tagName)
      ) {
        if (videoRef.current.paused || videoRef.current.ended) {
          videoRef.current.play();
          setIsPlaying(true);
        } else {
          videoRef.current.pause();
          setIsPlaying(false);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [videoRef]);

  const clickStartBeginning = () => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = 0;
  };

  return isMobile ? (
    <VideoPlayerMobile
      isPlaying={isPlaying}
      togglePlayPause={togglePlayPause}
      onTimeUpdate={onTimeUpdate}
      videoRef={videoRef}
      videoUrl={videoUrl}
      configuration={configuration}
      activeSubtitle={activeSubtitle}
      playerWidth={playerWidth}
    />
  ) : (
    <VideoPlayerDesktop
      togglePlayPause={togglePlayPause}
      clickStartBeginning={clickStartBeginning}
      isPlaying={isPlaying}
      volume={volume}
      mute={mute}
      handleMute={handleMute}
      handleVolumeChange={handleVolumeChange}
      clickFullscreen={clickFullscreen}
      clickGoBackward={clickGoBackward}
      clickGoForward={clickGoForward}
      currentTime={currentTime}
      videoRef={videoRef}
      videoUrl={videoUrl}
      activeSubtitle={activeSubtitle}
      configuration={configuration}
      onTimeUpdate={onTimeUpdate}
      playerWidth={playerWidth}
    />
  );
};

export default VideoPlayer;

import { useMediaQuery } from "react-responsive";
import { Caption as CaptionType } from "../../../../../../types";
import DesktopCaption from "./desktop";
import MobileCaption from "./mobile";
import { MOBILE_VIEW } from "../../../../../../variables";

interface CaptionProps {
  reference: any;
  captions: any;
  caption: CaptionType;
  onDelete: (key: string) => void;
  onTextChange: (key: string, value: string) => void;
  currentTime: number;
  clickSeek: (time: number) => void;
  setValue: any;
  formatTime: any;
  index: number;
  isEditing?: boolean;
  setIsEditing?: any;
}

const Caption = ({
  reference,
  captions,
  caption,
  onDelete,
  onTextChange,
  currentTime,
  clickSeek,
  setValue,
  formatTime,
  setIsEditing,
}: CaptionProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  return isMobile ? (
    <MobileCaption
      captionRef={reference}
      captions={captions}
      caption={caption}
      onDelete={onDelete}
      onTextChange={onTextChange}
      currentTime={currentTime}
      clickSeek={clickSeek}
      setValue={setValue}
      formatTime={formatTime}
      setIsEditing={setIsEditing}
    />
  ) : (
    <DesktopCaption
      captionRef={reference}
      captions={captions}
      caption={caption}
      onDelete={onDelete}
      onTextChange={onTextChange}
      currentTime={currentTime}
      clickSeek={clickSeek}
      setValue={setValue}
      formatTime={formatTime}
    />
  );
};

export default Caption;

import styled from "@emotion/styled";
import { Col, Row, Dropdown } from "react-bootstrap";

export const CaptionWrapper = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 1rem;
  border: 0.125rem dashed rgb(225, 225, 225);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 25px;
  transition: 0.25s;
  &.active {
    border: 0.125rem dashed rgb(47, 141, 228);
    background-color: rgb(229, 240, 249);
  }

  .caption-text {
    width: 100%;
    border: none;
    font-family: Poppins, sans-serif;
    color: grey;
    background: none;
    margin-top: 5px;
    transition: 0.25s;
    font-size: 14px;
    display: block;
    &:focus {
      outline: none !important;
      border: 2px solid #2f8de4;
      border-radius: 0.5rem;
      box-shadow: none;
      padding: 5px 10px;
      animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
    }

    @keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 975px) {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

export const UpperRow = styled(Row)``;

export const UpperCol = styled(Col)`
  &.delete {
    max-width: 70px;
  }
  &.left-aligned {
    text-align: left;
  }
  &.right-aligned {
    text-align: right;
  }
`;

export const DeleteButton = styled.button`
  border: none;
  background: none;
  font-size: 20px;
  color: grey;
`;

export const TimestampInput = styled.input`
  border: none;
  background: transparent;
  width: 100px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 14px;
  &:focus {
    outline: none !important;
    border: 2px solid #2f8de4;
    border-radius: 0.5rem;
    box-shadow: none;
  }
`;

export const BindingIcon = styled.span`
  display: inline;
  margin-left: 0px;
  margin-right: 10px;
  color: grey;
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
  padding: 0px;
  background: transparent !important;
  border: none;
  &::after {
    display: none;
  }
  &.show {
    background: none;
  }
  &:hover {
    background: #fff;
  }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  width: 290px;
  padding: 20px;
  h2 {
    margin-bottom: 5px !important;
  }
  button {
    width: 100%;
    height: 42.5px !important;
    margin-top: 15px;
  }
`;

export const AddButton = styled.button`
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 27.5px; /* Set a fixed width */
  height: 27.5px; /* Set the same height to make it a perfect circle */
  border-radius: 50%; /* Use 50% to ensure the button is round */
  background-color: #fff;
  color: grey;
  border: 0.125rem dashed rgb(225, 225, 225);
  font-size: 14px;
  display: flex; /* Center the content */
  align-items: center; /* Align content vertically */
  justify-content: center; /* Align content horizontally */
  padding: 0; /* Remove any default padding */

  &:hover {
    transition: 0.2s;
    border: 0.125rem dashed rgb(47, 141, 228);
    color: rgb(47, 141, 228);
    background-color: rgb(229, 240, 249);
  }

  &.active {
    transition: 0.2s;
    border: 0.125rem dashed rgb(47, 141, 228);
    color: rgb(47, 141, 228);
    background-color: rgb(229, 240, 249);

    &:hover {
      background-color: rgb(47, 141, 228);
      color: #fff;
    }
  }
`;

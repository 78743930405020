import { Dashboard } from "./styled";
import Navigation from "../../components/dashboard-upper-navigation";

interface IDashboardLayoutProps {
  showNavigation?: boolean;
  children: React.ReactNode;
}

const DashboardLayout: React.FC<IDashboardLayoutProps> = ({
  showNavigation = true,
  children,
}) => {
  return (
    <Dashboard className={showNavigation ? "" : "hide-navigation"}>
      {showNavigation && <Navigation />}
      {children}
    </Dashboard>
  );
};

export default DashboardLayout;

import useModal from "../../../../hooks/useModal";
import Button from "../../../../library/button";
import Input from "../../../../library/input";
import {
  ButtonCol,
  ContentContainer,
  InnerPageContainer,
  NotificationWrapper,
  PageContainer,
  SearchContainer,
  TableWrapper,
  TitleCol,
  UpperRow,
} from "./styled";
import { Title } from "../../../../library/title";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import AddModal from "./add-modal";
import useListTranscripts from "../../../../hooks/useTranscriptsList";
import SubscriptionModalContent from "../../../../components/subscription-modal";
import OnboardingModalContent from "../../../../components/onboarding-modal";
import Table from "./list-view";
import TableLoader from "../../../../library/table-loader";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDeletionArray,
  setSearchQuery,
} from "../../../../redux/actions/transcripts";
import useDeleteTranscripts from "../../../../hooks/useDeleteTranscripts";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../library/notification";
import { useTranslation } from "react-i18next";
import AttentionBox from "../../../../library/attention-box";

import useUser from "../../../../hooks/useUser";
import { IState } from "../../../../types";
import Tabs from "../../../../library/tabs";
import Meta from "./meta";
import Modal from "../../../../library/modal/new-modal";
import { useMediaQuery } from "react-responsive";

const MOBILE_VIEW = 800;

const Content = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [newTranscriptOpen, setNewTranscriptOpen] = useState<boolean>(false);
  const [onboardingOpen, setOnboardingOpen] = useState<boolean>(false);
  const [subscriptionOpen, setSubscriptionOpen] = useState<boolean>(false);
  const {
    openModal: openDeleteModal,
    Confirm: DeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();
  const { deleteTranscripts } = useDeleteTranscripts();
  const { fetchTranscriptsList, loading } = useListTranscripts();
  const { loading: loadingUserDetails } = useUser();

  const selectedWorkspace = useSelector(
    (state: IState) => state?.user?.selectedWorkspace
  );
  const user = useSelector((state: IState) => state?.user?.details);
  const privateTranscripts = useSelector(
    (state: IState) => state.transcripts?.records?.private
  );
  const publicTranscripts = useSelector(
    (state: IState) => state?.transcripts?.records?.public
  );

  const deletions = useSelector(
    (state: IState) => state?.transcripts?.deletionArray
  );
  const searchQuery = useSelector(
    (state: IState) => state?.transcripts?.searchQuery
  );

  const transcripts = useCallback(
    (type: "private" | "public") => {
      if (searchQuery) {
        if (type === "private") {
          return privateTranscripts?.filter((x) =>
            x.filename?.includes(searchQuery)
          );
        }
        return publicTranscripts?.filter((x) =>
          x.filename?.includes(searchQuery)
        );
      }

      return type === "private" ? privateTranscripts : publicTranscripts;
    },
    [privateTranscripts, publicTranscripts, searchQuery]
  );

  const renderList = (type: "private" | "public") => {
    if (loading) {
      return (
        <TableLoader
          message={
            t("dashboard.pages.transcripts.loading-view.message") as string
          }
        />
      );
    }
    return <Table data={transcripts(type)} />;
  };

  const SELECTIONS = [
    {
      id: 0,
      key: "private",
      title: t(
        "dashboard.pages.transcripts.tab-selections.private-transcriptions.title"
      ),
      ariaLabel: t(
        "dashboard.pages.transcripts.tab-selections.private-transcriptions.ariaLabel"
      ) as string,
      display: (
        <TableWrapper className={loading ? "loading" : ""}>
          {renderList("private")}
        </TableWrapper>
      ),
    },
    {
      id: 1,
      key: "shared",
      title: t(
        "dashboard.pages.transcripts.tab-selections.shared-transcriptions.title"
      ),
      ariaLabel: t(
        "dashboard.pages.transcripts.tab-selections.shared-transcriptions.ariaLabel"
      ) as string,
      display: (
        <TableWrapper className={loading ? "loading" : ""}>
          {renderList("public")}
        </TableWrapper>
      ),
    },
  ];

  useEffect(() => {
    if (selectedWorkspace?.id && user?.uid) {
      fetchTranscriptsList();
    }
  }, [fetchTranscriptsList, selectedWorkspace?.id, user?.onboarded, user?.uid]);

  useEffect(() => {
    if (user?.uid && !user?.onboarded) {
      setOnboardingOpen(true);
    }
  }, [setOnboardingOpen, user?.onboarded, user?.uid]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchQuery(e.target.value));
  };

  const handleCloseOnboardingModal = () => {
    setOnboardingOpen(false);
  };

  const handleOpenNewTranscriptModal = () => {
    setNewTranscriptOpen(true);
  };

  const handleCloseNewTranscriptModal = () => {
    setNewTranscriptOpen(false);
    fetchTranscriptsList();
  };

  const handleOpenSubscriptionModal = () => {
    setSubscriptionOpen(true);
  };

  const handleCloseSubscriptionModal = () => {
    setSubscriptionOpen(false);
  };

  const renderAttention = () => {
    if (!loadingUserDetails && selectedWorkspace?.subscription === "small") {
      return true;
    }
    return false;
  };

  const performDeleteTranscripts = async () => {
    try {
      const deletion = await deleteTranscripts(deletions);
      if (deletion?.error === null) {
        dispatch(clearDeletionArray());
        closeDeleteModal();
        sendSuccessNotification(
          t(
            "dashboard.universal.notifications.transcripts.delete-multiple.success"
          )
        );
        fetchTranscriptsList();
      } else {
        sendErrorNotification(
          t(
            "dashboard.universal.notifications.transcripts.delete-multiple.failed"
          )
        );
      }
    } catch (e) {
      sendErrorNotification(
        t(
          "dashboard.universal.notifications.transcripts.delete-multiple.failed"
        )
      );
    }
  };

  return (
    <>
      <Meta />
      <PageContainer>
        <NotificationWrapper>
          {renderAttention() && (
            <AttentionBox
              title={t("dashboard.pages.transcripts.upgrade-box.title")}
              text={t(t("dashboard.pages.transcripts.upgrade-box.description"))}
              icon="information"
              type="information"
              buttonText={
                t(
                  "dashboard.pages.transcripts.upgrade-box.button.title"
                ) as string
              }
              onClick={handleOpenSubscriptionModal}
            />
          )}
        </NotificationWrapper>
        <InnerPageContainer>
          <UpperRow>
            <TitleCol className="my-auto">
              <Title>{t("dashboard.pages.transcripts.title")}</Title>
            </TitleCol>
            <ButtonCol>
              <Button
                onClick={openDeleteModal}
                variant={isMobile ? "icon" : "regular"}
                color="grey"
                icon="trash"
                size={isMobile ? "lg" : "md"}
                spacing="right"
                disabled={deletions?.length === 0}
                ariaLabel={
                  t(
                    "dashboard.pages.transcripts.buttons.remove-transcript.ariaLabel"
                  ) as string
                }
              >
                {t(
                  "dashboard.pages.transcripts.buttons.remove-transcript.title"
                )}
              </Button>
              <Button
                onClick={handleOpenNewTranscriptModal}
                variant={isMobile ? "icon" : "regular"}
                color="blue"
                icon="plus"
                size={isMobile ? "lg" : "md"}
                ariaLabel={
                  t(
                    "dashboard.pages.transcripts.buttons.add-transcript.ariaLabel"
                  ) as string
                }
              >
                {t("dashboard.pages.transcripts.buttons.add-transcript.title")}
              </Button>
            </ButtonCol>
          </UpperRow>
          <SearchContainer>
            <Input
              variant="search"
              value={searchQuery}
              ariaLabel={
                t(
                  "dashboard.pages.transcripts.search-field.ariaLabel"
                ) as string
              }
              placeholder={`${t(
                "dashboard.pages.transcripts.search-field.placeholder"
              )}..`}
              onChange={handleSearch}
            />
          </SearchContainer>
          <ContentContainer>
            <Tabs selections={SELECTIONS} />
          </ContentContainer>
        </InnerPageContainer>

        <Modal
          open={newTranscriptOpen}
          title={t("dashboard.pages.transcripts.add-modal.title")}
          onClose={handleCloseNewTranscriptModal}
        >
          <AddModal closeModal={handleCloseNewTranscriptModal} />
        </Modal>
        <Modal
          open={onboardingOpen}
          onClose={handleCloseOnboardingModal}
          title="Velkommen til Randi!"
          size={1100}
        >
          <OnboardingModalContent onClose={handleCloseOnboardingModal} />
        </Modal>
        <Modal
          open={subscriptionOpen}
          onClose={handleCloseSubscriptionModal}
          title={t("dashboard.pages.workspace.change-subscription-modal.title")}
        >
          <SubscriptionModalContent onClose={handleCloseSubscriptionModal} />
        </Modal>
        <DeleteModal
          title={t("dashboard.pages.transcripts.delete-multiple-modal.title")}
          text={t("dashboard.pages.transcripts.delete-multiple-modal.text")!}
          submitAriaLabel={
            t(
              "dashboard.pages.transcripts.delete-multiple-modal.buttons.delete.ariaLabel"
            )!
          }
          submitText={
            t(
              "dashboard.pages.transcripts.delete-multiple-modal.buttons.delete.title"
            )!
          }
          onSubmit={performDeleteTranscripts}
        />
      </PageContainer>
    </>
  );
};

export default Content;

import { useCallback, useEffect, useRef } from "react";
import { SliderWrapper, WaveformWrapper } from "./styled";
import WaveSurfer from "wavesurfer.js";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";

type SliderProps = {
  audioUrl: any;
  audioUrlMobile: any;
  onSeek: any;
  currentTime: any;
  duration: any;
  setDataLoaded: any;
  dataLoaded: any;
  setShowError: any;
};

const Slider = ({
  audioUrl,
  audioUrlMobile,
  onSeek,
  currentTime,
  duration,
  setDataLoaded,
  dataLoaded,
  setShowError,
}: SliderProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);

  const supportsCompressedAudio = () => {
    const audioElement = document.createElement("audio");
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); // Check if browser is Safari

    // Check if browser supports OGG format
    const supportsOgg = audioElement.canPlayType("audio/ogg") !== "";

    // Safari doesn't support .ogg, so load the .mp3 file for Safari
    return isSafari || !supportsOgg; // Return true if Safari or OGG is not supported
  };

  wavesurferRef?.current?.on("ready", () => {
    setDataLoaded(true);
  });

  wavesurferRef.current?.on("error", (e) => {
    setShowError(true);
  });

  const handleSeek = useCallback(
    (event: any) => {
      if (!wavesurferRef.current || !waveformRef.current) return;

      const mouseX =
        event.clientX - waveformRef.current.getBoundingClientRect().left;
      const duration = wavesurferRef.current.getDuration();
      const seekTime = (mouseX / waveformRef.current.offsetWidth) * duration;

      onSeek(seekTime);
    },
    [onSeek]
  );

  useEffect(() => {
    if (!waveformRef.current) return;

    const currentWaveformRef = waveformRef.current;

    currentWaveformRef.addEventListener("click", handleSeek);

    return () => {
      if (currentWaveformRef) {
        currentWaveformRef.removeEventListener("click", handleSeek);
      }
    };
  }, [handleSeek]);

  useEffect(() => {
    if (!waveformRef.current) return;

    const audio = supportsCompressedAudio() ? audioUrlMobile : audioUrl;

    if (!audio) return;

    wavesurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#74bcff",
      progressColor: "#2f8de4",
      cursorWidth: 1,
      barWidth: 3,
      height: 20,
    });

    wavesurferRef.current.load(audio);

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [audioUrl, audioUrlMobile, isMobile]);

  useEffect(() => {
    if (currentTime && duration && wavesurferRef.current) {
      wavesurferRef.current?.seekTo(currentTime / duration);
    }
  }, [currentTime, duration]);

  return (
    <SliderWrapper className={dataLoaded ? "show" : "hide"}>
      <WaveformWrapper ref={waveformRef} />
    </SliderWrapper>
  );
};

export default Slider;

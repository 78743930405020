import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../library/button";
import Input from "../../../../library/input";
import TableLoader from "../../../../library/table-loader";
import Tabs from "../../../../library/tabs";
import { Title } from "../../../../library/title";
import Table from "./list-view";
import {
  ButtonCol,
  ContentContainer,
  InnerPageContainer,
  NotificationWrapper,
  PageContainer,
  SearchContainer,
  TableWrapper,
  TitleCol,
  UpperRow,
} from "./styled";
import AddModal from "./add-modal";
import { IState } from "../../../../types";
import SubscriptionModalContent from "../../../../components/subscription-modal";
import useListSoundtracks from "../../../../hooks/useSoundtracksList";
import useUser from "../../../../hooks/useUser";
import { useTranslation } from "react-i18next";
import {
  clearDeletionArray,
  setSearchQuery,
} from "../../../../redux/actions/soundtracks";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../library/notification";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import useModal from "../../../../hooks/useModal";
import useDeleteSoundtracks from "../../../../hooks/useDeleteSoundtracks";
import AttentionBox from "../../../../library/attention-box";
import Modal from "../../../../library/modal/new-modal";
import Meta from "./meta";
import { useMediaQuery } from "react-responsive";

const MOBILE_VIEW = 800;

const Content = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const [newSoundtrackOpen, setNewSoundtrackOpen] = useState<boolean>(false);
  const [subscriptionModalOpen, setSubscriptionModalOpen] =
    useState<boolean>(false);
  const {
    openModal: openDeleteModal,
    Confirm: DeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();
  const { deleteSoundtracks } = useDeleteSoundtracks();
  const { fetchSoundtracksList, loading } = useListSoundtracks();
  const { loading: loadingUserDetails } = useUser();

  const selectedWorkspace = useSelector(
    (state: IState) => state?.user?.selectedWorkspace
  );
  const user = useSelector((state: IState) => state?.user?.details);
  const privateSoundtracks = useSelector(
    (state: IState) => state?.soundtracks?.records?.private
  );
  const publicSoundtracks = useSelector(
    (state: IState) => state?.soundtracks?.records?.public
  );

  const deletions = useSelector(
    (state: IState) => state?.soundtracks?.deletionArray
  );
  const searchQuery = useSelector(
    (state: IState) => state?.soundtracks?.searchQuery
  );

  const soundtracks = useCallback(
    (type: "private" | "public") => {
      if (searchQuery) {
        if (type === "private") {
          return privateSoundtracks.filter((x) =>
            x.title.includes(searchQuery)
          );
        }
        return publicSoundtracks.filter((x) => x.title.includes(searchQuery));
      }
      if (type === "private") {
        return privateSoundtracks;
      }
      return publicSoundtracks;
    },
    [privateSoundtracks, publicSoundtracks, searchQuery]
  );

  const renderList = (type: "private" | "public") => {
    if (loading) {
      return (
        <TableLoader
          message={
            t("dashboard.pages.soundtracks.loading-view.message") as string
          }
        />
      );
    }
    return <Table data={soundtracks(type)} />;
  };

  const SELECTIONS = [
    {
      id: 0,
      key: "private",
      title: t(
        "dashboard.pages.soundtracks.tab-selections.private-soundtracks.title"
      ),
      ariaLabel: t(
        "dashboard.pages.soundtracks.tab-selections.private-soundtracks.ariaLabel"
      ) as string,
      display: (
        <TableWrapper className={false ? "loading" : ""}>
          {renderList("private")}
        </TableWrapper>
      ),
    },
    {
      id: 1,
      key: "shared",
      title: t(
        "dashboard.pages.soundtracks.tab-selections.shared-soundtracks.title"
      ),
      ariaLabel: t(
        "dashboard.pages.soundtracks.tab-selections.shared-soundtracks.ariaLabel"
      ) as string,
      display: (
        <TableWrapper className={loading ? "loading" : ""}>
          {renderList("public")}
        </TableWrapper>
      ),
    },
  ];

  useEffect(() => {
    if (selectedWorkspace?.id && user?.uid) {
      fetchSoundtracksList();
    }
  }, [fetchSoundtracksList, selectedWorkspace?.id, user?.uid]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchQuery(e.target.value));
  };

  const openNewSoundtrack = () => {
    setNewSoundtrackOpen(true);
  };

  const closeNewSoundtrack = () => {
    setNewSoundtrackOpen(false);
    fetchSoundtracksList();
  };

  const openSubscriptionModal = () => {
    setSubscriptionModalOpen(true);
  };

  const closeSubscriptionModal = () => {
    setSubscriptionModalOpen(false);
  };

  const renderAttention = () => {
    if (
      !loadingUserDetails &&
      selectedWorkspace?.subscription === "small" &&
      selectedWorkspace?.role === "administrator"
    ) {
      return true;
    }
    return false;
  };

  const performDeleteSoundtracks = async () => {
    try {
      const deletion = await deleteSoundtracks(deletions);
      if (deletion!.error === null) {
        dispatch(clearDeletionArray());
        closeDeleteModal();
        sendSuccessNotification(
          t(
            "dashboard.universal.notifications.soundtracks.delete-multiple.success"
          )
        );
        fetchSoundtracksList();
      } else {
        sendErrorNotification(
          t(
            "dashboard.universal.notifications.soundtracks.delete-multiple.failed"
          )
        );
      }
    } catch (e) {
      sendErrorNotification(
        t(
          "dashboard.universal.notifications.soundtracks.delete-multiple.failed"
        )
      );
    }
  };

  return (
    <>
      <Meta />
      <PageContainer>
        <NotificationWrapper>
          {renderAttention() && (
            <AttentionBox
              title={t("dashboard.pages.soundtracks.upgrade-box.title")}
              text={t(t("dashboard.pages.soundtracks.upgrade-box.description"))}
              icon="information"
              type="information"
              buttonText={
                t(
                  "dashboard.pages.soundtracks.upgrade-box.button.title"
                ) as string
              }
              onClick={openSubscriptionModal}
            />
          )}
        </NotificationWrapper>
        <InnerPageContainer>
          <UpperRow>
            <TitleCol>
              <Title>{t("dashboard.pages.soundtracks.title")}</Title>
            </TitleCol>
            <ButtonCol>
              <Button
                onClick={openDeleteModal}
                variant={isMobile ? "icon" : "regular"}
                size={isMobile ? "lg" : "md"}
                color="grey"
                icon="trash"
                spacing="right"
                disabled={deletions?.length === 0}
                ariaLabel={
                  t(
                    "dashboard.pages.soundtracks.buttons.remove-transcript.ariaLabel"
                  ) as string
                }
              >
                {t(
                  "dashboard.pages.soundtracks.buttons.remove-soundtrack.title"
                )}
              </Button>
              <Button
                onClick={openNewSoundtrack}
                variant={isMobile ? "icon" : "regular"}
                color="blue"
                icon="plus"
                size={isMobile ? "lg" : "md"}
                ariaLabel={
                  t(
                    "dashboard.pages.soundtracks.buttons.add-soundtrack.ariaLabel"
                  ) as string
                }
              >
                {t("dashboard.pages.soundtracks.buttons.add-soundtrack.title")}
              </Button>
            </ButtonCol>
          </UpperRow>
          <SearchContainer>
            <Input
              variant="search"
              value={searchQuery}
              ariaLabel={
                t(
                  "dashboard.pages.soundtracks.search-field.ariaLabel"
                ) as string
              }
              placeholder={`${t(
                "dashboard.pages.soundtracks.search-field.placeholder"
              )}..`}
              onChange={handleSearch}
            />
          </SearchContainer>
          <ContentContainer>
            <Tabs selections={SELECTIONS} />
          </ContentContainer>
        </InnerPageContainer>
        <Modal
          open={newSoundtrackOpen}
          onClose={closeNewSoundtrack}
          title={t("dashboard.pages.soundtracks.add-modal.title")}
        >
          <AddModal closeModal={closeNewSoundtrack} />
        </Modal>
        <DeleteModal
          title={t("dashboard.pages.soundtracks.delete-multiple-modal.title")}
          text={t("dashboard.pages.soundtracks.delete-multiple-modal.text")!}
          submitAriaLabel={
            t(
              "dashboard.pages.soundtracks.delete-multiple-modal.buttons.delete.ariaLabel"
            )!
          }
          submitText={
            t(
              "dashboard.pages.soundtracks.delete-multiple-modal.buttons.delete.title"
            )!
          }
          onSubmit={performDeleteSoundtracks}
        />
        <Modal
          open={subscriptionModalOpen}
          onClose={closeSubscriptionModal}
          title={t("dashboard.pages.workspace.change-subscription-modal.title")}
        >
          <SubscriptionModalContent onClose={closeSubscriptionModal} />
        </Modal>
      </PageContainer>
    </>
  );
};

export default Content;

import Input from "../../../../../../library/input";
import { Label } from "../../../../../../library/label";
import {
  AccordionTitle,
  Field,
  InnerAccordionHeader,
  InputCol,
  InputRow,
} from "../styled";
import { AccordionBody, AccordionHeader, AccordionItem } from "../styled";
import ColorSelect from "../../../../../../library/color-select";
import { ColorResult } from "react-color";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { VideoAppearanceSchema } from "../../schema";
import FontPicker from "../../../../../../library/font-picker";
import ToggleButtons from "../../../../../../library/toggle-buttons";
import { getIcon } from "../../../../../../utils/get-icon";

interface ITextSettingsProps {
  isMobile: boolean;
  appearance: any;
  setValue: UseFormSetValue<VideoAppearanceSchema>;
  keyRef: string;
  register: UseFormRegister<VideoAppearanceSchema>;
  fonts: any;
  updateGoogleFontsLink: any;
}

const TextSettings: React.FC<ITextSettingsProps> = ({
  isMobile,
  appearance,
  setValue,
  keyRef,
  register,
  fonts,
  updateGoogleFontsLink,
}) => {
  const handleColorChange = (color: ColorResult) => {
    const { r, g, b, a } = color.rgb;
    const rgbaString = `rgba(${r}, ${g}, ${b}, ${a})`;
    setValue("appearance.color", rgbaString);
  };

  const onResetFontColor = () => {
    setValue("appearance.color", "#ffffff");
  };

  const handleFontSelect = (selected: any) => {
    const selectedFont = fonts.find(
      (font: any) => font.family === selected.value
    );
    if (selectedFont) {
      updateGoogleFontsLink(selectedFont);
      setValue("appearance.fontStyle", "normal");
      setValue("appearance.fontFamily", selectedFont.family);
    }
  };

  const onSetStyle = (variant: "normal" | "italic" | "bold") => {
    setValue("appearance.fontStyle", variant);
  };

  const onSetDecoration = (variant: "none" | "underline" | "line-through") => {
    setValue("appearance.decoration", variant);
  };

  const renderItalicAvailable = () => {
    const selectedFont = fonts?.find(
      (font: any) => font.family === appearance.fontFamily
    );

    if (selectedFont) {
      const variants = selectedFont.variants;
      return !variants.some((variant: string) => variant?.includes("italic"));
    }

    return false;
  };

  const renderBoldAvailable = () => {
    const selectedFont = fonts?.find(
      (font: any) => font.family === appearance.fontFamily
    );

    if (selectedFont) {
      const variants = selectedFont.variants;
      return !variants.some((variant: string) => {
        const weight = parseInt(variant.replace(/\D/g, ""), 10);
        return weight >= 500;
      });
    }

    return false;
  };

  return (
    <AccordionItem key={keyRef} eventKey={keyRef}>
      <AccordionHeader>
        <InnerAccordionHeader>
          <AccordionTitle>{getIcon("")} Font</AccordionTitle>
        </InnerAccordionHeader>
      </AccordionHeader>
      <AccordionBody>
        <Field isMobile={isMobile}>
          <InputRow>
            <InputCol md={6}>
              <Label>Font</Label>
              <FontPicker
                fonts={fonts}
                selectedFont={appearance?.fontFamily}
                onSelect={handleFontSelect}
              />
            </InputCol>
            <InputCol md={6} sm={12} xs={12}>
              <Label>Dekorasjon</Label>
              <ToggleButtons
                selected={appearance?.decoration ?? "none"}
                className="toggle-buttons"
                options={[
                  {
                    title: getIcon("dash"),
                    value: "none",
                  },
                  {
                    title: getIcon("underline"),
                    value: "underline",
                  },
                  {
                    title: getIcon("strikethrough"),
                    value: "line-through",
                  },
                ]}
                onSelect={onSetDecoration}
              />
            </InputCol>
            <InputCol md={6} sm={12} xs={12}>
              <Label>Variant</Label>
              <ToggleButtons
                selected={appearance?.fontStyle ?? "normal"}
                className="toggle-buttons"
                options={[
                  {
                    title: getIcon("regular-text"),
                    value: "normal",
                  },
                  {
                    title: getIcon("italic-text"),
                    value: "italic",
                    disabled: renderItalicAvailable(),
                  },
                  {
                    title: getIcon("bold-text"),
                    value: "bold",
                    disabled: renderBoldAvailable(),
                  },
                ]}
                onSelect={onSetStyle}
              />
            </InputCol>
            <InputCol md={6}>
              <Label>Størrelse</Label>
              <Input
                variant="numeric"
                register={register}
                name="appearance.fontSize"
              />
            </InputCol>
            <InputCol md={6}>
              <Label>Farge</Label>
              <ColorSelect
                color={appearance?.color}
                onChange={handleColorChange}
                onReset={onResetFontColor}
              />
            </InputCol>
          </InputRow>
        </Field>
      </AccordionBody>
    </AccordionItem>
  );
};

export default TextSettings;

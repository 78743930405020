import Caption from "./caption";
import { TimelineContainer } from "./styled";

type TimelineProps = {
  currentTime: number;
  videoRef: any;
  onDelete: any;
  onTextChange: any;
  captions?: any[];
  formatTime: any;
  setValue: any;
  activeCaptionRef: any;
  scrollingContainerRef?: any;
  setIsEditing?: any;
  isEditing?: boolean;
};

export const Timeline = ({
  currentTime,
  videoRef,
  onDelete,
  onTextChange,
  captions,
  formatTime,
  setValue,
  scrollingContainerRef,
  activeCaptionRef,
  setIsEditing,
  isEditing,
}: TimelineProps) => {
  const clickSeek = (start: number) => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = start;
  };

  return (
    <TimelineContainer ref={scrollingContainerRef}>
      {captions?.map((caption, index) => (
        <Caption
          key={caption?.key}
          captions={captions}
          caption={caption}
          onDelete={onDelete}
          onTextChange={onTextChange}
          currentTime={currentTime}
          clickSeek={clickSeek}
          reference={activeCaptionRef}
          formatTime={formatTime}
          setValue={setValue}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          index={index}
        />
      ))}
    </TimelineContainer>
  );
};

export default Timeline;
